import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import Swiper from "react-id-swiper"
import "react-id-swiper/lib/styles/css/swiper.css"
import { LazyLoadComponent } from "react-lazy-load-image-component"

// LOGIN ICONS
import OnePoint from "../images/benefits/onepoint.png"
import Humana from "../images/benefits/humana.png"
import Metlife from "../images/benefits/metlife.png"
import Aetna from "../images/benefits/aetna.png"
import Unum from "../images/benefits/unum.png"
import Tfhour from "../images/benefits/24hour.png"
import LTTrust from "../images/benefits/lttrust.png"
import Alerus from "../images/benefits/alerus_logo.png"
import Anthem from "../images/benefits/anthem.png"

// import BenefitsDiagram from "../images/benefits-diagram-v2.svg"
import BenefitsDiagram from "../components/svg/ContractorBenefits"

const params = {
  slidesPerView: 4,
  spaceBetween: 15,
  loop: true,
  speed: 700,
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },
  navigation: {
    nextEl: "#logins-next",
    prevEl: "#logins-prev",
  },
  breakpoints: {
    1200: {
      slidesPerView: 4,
    },
    992: {
      slidesPerView: 3,
    },
    475: {
      slidesPerView: 1,
    },
  },
}

const Benefits = () => {
  return (
    <Layout page="benefits">
      <div className="benefits-page" style={{ paddingTop: "200px" }}>
        <h1 className="text-center benefits-flair mb-5 text-dark">BENEFITS</h1>

        <Container style={{ paddingBottom: "100px" }}>
          <Row className="justify-content-center">
            <Col lg={9}>
              <h4 className="mb-5">
                Without our talented candidates like you, there would be no
                PeerSource! When you choose PeerSource to help you find the
                perfect position, we are committed to offer the best recruiting
                experience you have ever received.
              </h4>
              <p className="mb-5">
                We take the time to listen to your goals, motivations, and
                skills. We offer resume coaching, interview preparation, and
                help with your transition into your new role. We don’t just
                place you and forget about you, or go dark on you during an
                interview process. Rather, we look to build long-term
                relationships with all our candidates by maintaining regular
                contact and providing you an excellent service.
              </p>
              <p className="mb-5">
                All full-time W2 consultants of PeerSource receive access to our
                fantastic benefits. We also contribute to your health benefit
                costs a set percentage on top of your hourly rate!
              </p>
            </Col>
          </Row>
        </Container>

        <Container
          fluid
          className="benefit-contactor"
          style={{ padding: "100px 0", backgroundColor: "#F9F9F9" }}
        >
          <Container className="text-center">
            <h1 className="text-center benefits-flair mb-5 text-dark text-center">
              PEERSOURCE CONTRACTOR BENEFITS
            </h1>
            <BenefitsDiagram />
          </Container>
        </Container>

        <Container
          fluid
          className="benefit-logins"
          style={{ padding: "100px 0" }}
        >
          <Container>
            <h1 className="text-center benefits-flair mb-5 text-white text-center">
              BENEFIT LOGINS
            </h1>

            <LazyLoadComponent>
              <div className="benefit-logins-swiper-wrapper">
                <div className="logins-nav-buttons">
                  <div
                    id="logins-next"
                    tabindex="0"
                    role="button"
                    aria-label="Next slide"
                  ></div>
                  <div
                    id="logins-prev"
                    tabindex="0"
                    role="button"
                    aria-label="Previous slide"
                  ></div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-11">
                    <Swiper {...params}>
                      <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img
                          className="img-fluid"
                          src={OnePoint}
                          alt="OnePoint"
                        />
                        <p>
                          Employee Login – View paystubs, access your W2, and
                          complete open enrollment.
                        </p>

                        <a
                          href="https://onp.prismhr.com/onp/auth/#/login?lang=en"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                      </div>
                      {/* 
                      <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img className="img-fluid" src={Humana} alt="Humana" />
                        <p>
                          Health Plan Participants-Find a provider, access
                          wellness plan resources, and get RX information.
                        </p>
                        <a
                          href="https://account.humana.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                        <button className="blue-btn border-none" type="submit">
                          Login
                        </button>
                      </div> */}

                      <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img className="img-fluid" src={Anthem} alt="Anthem" />
                        <p>
                          Health, Dental, and Vision Plan Participants – Find a
                          Provider, print ID cards, and process claims.
                        </p>
                        <a
                          href="https://www.anthem.com/account-login/"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                        {/* <button className="blue-btn border-none" type="submit">
                          Login
                        </button> */}
                      </div>

                      <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img
                          className="img-fluid"
                          src={Alerus}
                          alt="24 Hour Flex"
                        />
                        <p>
                          Flex Plan Participants – Access forms, find
                          information on reimbursable expenses, file a claim,
                          and find a retailer that accepts these debit cards.
                        </p>
                        <a
                          href="https://www.alerusrb.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                        {/* <button className="blue-btn border-none" type="submit">
                          Login
                        </button> */}
                      </div>

                      {/* <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img
                          className="img-fluid"
                          src={Metlife}
                          alt="MetLife"
                        />
                        <p>
                          Dental Plan Participants-Find a Dentist and process
                          claims.
                        </p>
                        <a
                          href="https://identity.metlife.com/public/login"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                        <button className="blue-btn border-none" type="submit">
                          Login
                        </button>
                      </div> */}

                      {/* <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img className="img-fluid" src={Aetna} alt="Aetna" />
                        <p>
                          Vision Plan Participants-Find a Provider and process
                          claims.
                        </p>
                        <a
                          href="https://www.aetna.com/about-us/login.html"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                        <button className="blue-btn border-none" type="submit">
                          Login
                        </button>
                      </div> */}

                      <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img className="img-fluid" src={Unum} alt="unum" />
                        <p>
                          Life and AD&D Login – Understand your coverage, access
                          forms, and file a claim.
                        </p>
                        <a
                          href="https://services.unum.com/_fwLogin/"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                      </div>

                      <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img
                          className="img-fluid"
                          src={LTTrust}
                          alt="LT Trust"
                        />
                        <p>
                          401(k) Participants – Manage your elections and
                          contributions.
                        </p>
                        <a
                          href="https://401k.ltretire.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                      </div>
                    </Swiper>
                  </div>
                </div>
              </div>
            </LazyLoadComponent>
          </Container>
        </Container>

        <Container
          fluid
          className="benefit-tech text-center"
          style={{ padding: "100px 0" }}
        >
          <Container>
            <h3
              className="text-center btech-flair mb-5 text-white text-center"
              style={{ maWidth: "33em", margin: "0 auto" }}
            >
              Want to make some additional money leveraging your own network?
              <br />
              Find out how with the PeerSource Referral Program!
            </h3>

            <Link
              className="white-btn border-none"
              to="/referral-program"
              style={{ fontSize: "20px" }}
            >
              Learn More
            </Link>
          </Container>
        </Container>
      </div>
    </Layout>
  )
}

export default Benefits
